import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              Page Not Found
            </h1>
            Oops, whatever you're looking for isn't here. Head back{" "}
            <Link to="/">home</Link>?
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
